<template>
    <header class="header-block">
      <!--header page-->
      <div class="header-page">
        <div class="header-col">
          <a href="#" class="btn btn-white btn-burger" aria-label="btn-burger" v-on:click="openMenu">
            <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1H19M1 7H19M1 13H19" stroke="#F3C678" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </a>
        </div>
        <div class="header-col">
            <div class="header-logo">
              <!-- <router-link :to="{ name:"Home", params: { lang: this.$i18n.locale } }" routeTo="/" @click.native="updateDataSwitch">
                <img  width="140" height="160" :src='this.$pathprod+"images/home/Logo-rhapsody.svg" alt="Logo-rhapsody">
              </router-link> -->
            </div>
        </div>
        <div class="header-col header-right">
            <div class="switch-block">
              <label for="switch" class="link-switch link-harmony">Harmony</label>
              <input type="checkbox" id="switch" :lastUrl="val" :idUrl="val2" class="switch-checkbox" v-on:click="switchUrl" checked>
              <label for="switch" class="switch_label">
                <span class="onf_btn"></span>
              </label>
              <label class="link-switch link-rhapsody" active-class="active">Rhapsody</label>
            </div>
        </div>
      </div>
      <!--header page-->

      <!--Menu-->
      <div class="menu" :class="{'show': showMenu, 'animateBrush': animateCloseMenu}" id="menuPage">
        <a href="#" class="btn btn-white btn-close" v-on:click="closeMenu">
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.635934 13.364L13.3639 0.636039M0.635934 0.636039L13.3639 13.364" stroke="#F3C678" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </a>
        <div class="menu-block-container">
          <div class="menu-bg-grid">
            <div class="menu-grid-left">
              <div class="img-block img-block-1">
                <lottie-animation class="brush-lottie"
                  ref="brushOne"
                  :animationData="require('@/assets/lottie/brush-menu-last.json')"
                  :loop="false"
                  :autoPlay="false"
                  :speed="2"
                />
              </div>
              <div class="img-block img-block-2">
                <lottie-animation class="brush-lottie"
                  ref="brushTwo"
                  :animationData="require('@/assets/lottie/brush-menu-last.json')"
                  :loop="false"
                  :autoPlay="false"
                  :speed="1"
                />
              </div>
              <div class="img-block img-block-3">
                <lottie-animation class="brush-lottie"
                  ref="brushThree"
                  :animationData="require('@/assets/lottie/brush-menu-last.json')"
                  :loop="false"
                  :autoPlay="false"
                  :speed="1"
                />
              </div>
              <div class="img-block img-block-4">
                <lottie-animation class="brush-lottie"
                  ref="brushFour"
                  :animationData="require('@/assets/lottie/brush-menu-last.json')"
                  :loop="false"
                  :autoPlay="false"
                  :speed="1"
                />
              </div>
            </div>
          </div>
          <div class="menu-block">
            <div class="menu-left">
              <div class="menu-left-content">
                <!--Menu list-->
                <ul class="menu-list">
                  <li class="menu-list-item">
                    <router-link :to="{ name: 'Home', params: { lang: this.$i18n.locale }}" routeTo="/" class="link-menu" @click.native="sameLinkClicked" data-page="accueil">{{$t('headerMenu')['0']}}</router-link>
                  </li>
                  <li class="menu-list-item">
                    <router-link :to="{ name: 'SaintOuen', params: { lang: this.$i18n.locale }}" class="link-menu" routeTo="/saint-ouen" @click.native="sameLinkClicked" data-main-page="page-saint-ouen">{{$t('headerMenu')['1']}}</router-link>
                    <ul class="menu-sublist">
                      <li class="sublist-item">
                        <router-link :to="{ name: 'SaintOuen', hash: '#suivre-ligne', params: { lang: this.$i18n.locale }}" class="sublink-menu" @click.native="sameLinkClicked">{{$t('headerMenu')['2']}}</router-link>
                      </li>
                      <li class="sublist-item">
                        <router-link :to="{ name: 'SaintOuen', hash: '#mobilite-douce', params: { lang: this.$i18n.locale }}" class="sublink-menu" @click.native="sameLinkClicked">{{$t('headerMenu')['3']}}</router-link>
                      </li>
                      <li class="sublist-item">
                        <router-link :to="{ name: 'SaintOuen', hash: '#saint-ouen-vie', params: { lang: this.$i18n.locale }}" class="sublink-menu" @click.native="sameLinkClicked">{{$t('headerMenu')['4']}}</router-link>
                      </li>
                      <li class="sublist-item">
                        <router-link :to="{ name: 'SaintOuen', hash: '#ca-bouge', params: { lang: this.$i18n.locale }}" class="sublink-menu" @click.native="sameLinkClicked">{{$t('headerMenu')['5']}}</router-link>
                      </li>
                      <li class="sublist-item">
                        <router-link :to="{ name: 'SaintOuen', hash: '#envole-tertiaire', params: { lang: this.$i18n.locale }}" class="sublink-menu" @click.native="sameLinkClicked">{{$t('headerMenu')['6']}}</router-link>
                      </li>
                    </ul>
                  </li>
                  <li class="menu-list-item">
                    <router-link :to="{ name: 'CompositionInclusive', params: { lang: this.$i18n.locale }}" routeTo="/composition-inclusive" class="link-menu" @click.native="sameLinkClicked" >{{$t('headerMenu')['7']}}</router-link>
                    <ul class="menu-sublist">
                      <li class="sublist-item">
                        <router-link :to="{ name: 'CompositionInclusive', hash: '#esquisse-projet', params: { lang: this.$i18n.locale }}" routeTo="/composition-inclusive#esquisse-projet" class="sublink-menu" @click.native="sameLinkClicked">{{$t('headerMenu')['8']}}</router-link>
                      </li>
                      <li class="sublist-item">
                        <router-link :to="{ name: 'CompositionInclusive', hash: '#espace-rassembleurs', params: { lang: this.$i18n.locale }}" routeTo="/composition-inclusive#rassemblement" class="sublink-menu" @click.native="sameLinkClicked">{{$t('headerMenu')['9']}}</router-link>
                      </li>
                      <li class="sublist-item">
                        <router-link :to="{ name: 'CompositionInclusive', hash: '#pollinisateur', params: { lang: this.$i18n.locale }}" routeTo="/composition-inclusive#pollinisateur" class="sublink-menu" @click.native="sameLinkClicked">{{$t('headerMenu')['10']}}</router-link>
                      </li>
                      <li class="sublist-item">  
                        <router-link :to="{ name: 'CompositionInclusive', hash: '#roof-top', params: { lang: this.$i18n.locale }}" routeTo="/composition-inclusive#roof-top" class="sublink-menu" @click.native="sameLinkClicked">{{$t('headerMenu')['11']}}</router-link>
                      </li>
                    </ul>
                  </li>
                  <li class="menu-list-item">
                    <router-link :to="{ name: 'TraiteRhapsody', params: { lang: this.$i18n.locale }}" routeTo="/traite-rhapsody" class="link-menu" @click.native="sameLinkClicked">{{$t('headerMenu')['12']}}</router-link>
                  </li>
                  <li class="menu-list-item">
                    <router-link :to="{ name: 'Contact', params: { lang: this.$i18n.locale }}" routeTo="/contact" class="link-menu" @click.native="sameLinkClicked" data-main-page="page-contact">{{$t('headerMenu')['13']}}</router-link>
                  </li>
                  <li class="menu-list-item">
                    <router-link :to="{ name: 'Telechargement', params: { lang: this.$i18n.locale }}" routeTo="/telechargement" class="link-menu" @click.native="sameLinkClicked" data-main-page="page-telechargement">{{$t('headerMenu')['14']}}</router-link>
                  </li>
                  <!--**********************
                       Switch langue
                  ***********************-->
                  <li class="menu-list-item switch_lang">
                     <a href="javacript;;" class="link-menu separator_barre">
                        <input type="radio" id="french" name="lang-selector" class="d-none" value="fr" v-model="lang" @click="switch_lang()">
                        <label for="french" class="switch_fr menu-lang">FR</label>
                     </a>
                     <a href="javacript;;" class="link-menu">
                        <input type="radio" id="english" name="lang-selector" class="d-none" value="en" v-model="lang" @click="switch_lang()">
                        <label for="english" class="switch_uk menu-lang">UK</label>
                     </a>
                  </li>
                  <input type="text" id="path_lang" class="d-none" :value="lang">
                </ul>
                <!--Menu list-->
                <!--logo partner mobile-->
                <div class="list-partner">
                  <a class="list-item" href="https://fr.linkedin.com/company/cdc-investissement-immobilier" target="_blank"><img :src='this.$pathprod+"images/logo/logo-CDC-Invest-Immo.svg"' alt="CDC-Invest-Immo" width="121" height="19"></a>
                  <a class="list-item" href="https://www.allianz.fr/" target="_blank"><img :src='this.$pathprod+"images/logo/logo-allianz.svg"' alt="Allianz" width="108" height="27"></a>
                  <a class="list-item" href="https://mabanque.bnpparibas/" target="_blank"><img :src='this.$pathprod+"images/logo/logo-bnp-paribas.svg"' alt="BNP paribas" width="119" height="26"></a>
                  <a class="list-item" href="https://www.emerige.com/" target="_blank"><img :src='this.$pathprod+"images/logo/logo-emerige.svg"' alt="Emerige" width="70" height="36"></a>
                  <router-link :to="{ name: 'MentionsLegales', params: { lang: this.$i18n.locale }}" class="lien-legale" @click.native="sameLinkClicked">{{$t('headerMenu')['15']}}</router-link>
                </div>
                <!--/logo partner mobile-->
              </div>
            </div>
          </div>
        </div>
        
      </div>
      <!--Menu-->
    </header>
</template>

<script>
import LottieAnimation from 'lottie-web-vue'
export default {
   name: 'Header',
   components: {
      LottieAnimation,
   },
   props: {
      msg: String,
   },
   data:() => ({
         showMenu: false,
         animateCloseMenu: false,
         showBrushRight: false,
         val: '/',
         val2: '0',
         playVideo: false,
   }),
   methods: {
      openMenu(e){
         e.preventDefault();
         this.showMenu = true;
         this.animateCloseMenu = false;
         setTimeout(function(){
            document.body.classList.add('open-menu');
         },100);
         setTimeout(function(){
            document.querySelector('.menu-list').classList.add('showM');
         },1200)
         
         setTimeout(function () {
            this.$refs.brushOne.play();
            this.$refs.brushTwo.play();
         }.bind(this), 600)

         setTimeout(function () {
            this.$refs.brushThree.play();
         }.bind(this), 800)

         setTimeout(function () {
            this.$refs.brushFour.play();
         }.bind(this), 1000)
      },

      sameLinkClicked(){
         this.showMenu = true;
         this.animateCloseMenu = true;

         setTimeout(function(){
            document.body.classList.remove('open-menu');
            document.querySelector('.menu-list').classList.remove('showM');
            let arrayImgBlock = document.querySelectorAll('.img-block'); 
               for(let i =0; i < arrayImgBlock.length; i++){
                  arrayImgBlock[i].classList.remove('slots-enter-active');
               }
            this.$refs.brushOne.stop();
            this.$refs.brushTwo.stop();
            this.$refs.brushThree.stop();
            this.$refs.brushFour.stop();

         }.bind(this),300)

         setTimeout(function(){
            this.showMenu = false;
         }.bind(this), 500)

      //-------------------------------------------------------
         this.playEnteteVideo("#saintOuen-1")
         this.playEnteteVideo(".section-first")
         this.playEnteteVideo(".tl-ctnLeft")
      },

      closeMenu(e){
         e.preventDefault();
         this.animateCloseMenu = true;
         setTimeout(function(){
            document.body.classList.remove('open-menu');
            document.querySelector('.menu-list').classList.remove('showM');
         }.bind(this),300)

         setTimeout(function(){
            this.showMenu = false;
         }.bind(this), 500);

         setTimeout(function () {
            this.$refs.brushOne.stop();
            this.$refs.brushTwo.stop();
            this.$refs.brushThree.stop();
            this.$refs.brushFour.stop();
         }.bind(this), 700)
      },

      scrollToSection(e, element){
         e.preventDefault();
         var container = this.$el.querySelector(element);
         container.scrollTop = container.scrollHeight;
         container.scrollIntoView({behavior: 'smooth'});
      },

      playEnteteVideo(enteteVideo){
         var video = document.querySelector(enteteVideo + " .bg-hero")
         if(video){
            video.play()           
            var inter = setInterval(() =>{
                  if(video.paused && video.ended == false ){
                     video.play()
                  }
            }, 500);
            
            video.addEventListener("ended", function(){
                  clearInterval(inter)
            })
         }
      },

      updateDataSwitch(e){
         let valHref = e.target.parentNode.getAttribute('routeTo');
         let valHrefID = e.target.parentNode.getAttribute('data-same-link');
         this.val = valHref;
         this.val2 = valHrefID;
      },

      appearLottieAnmi(){
         this.$refs.brushOne.play();
      },
      
      appearLottieAnmi2(){
         this.$refs.brushTwo.play();
      },

      switchUrl(){
         var current_lang = document.getElementById("path_lang").value
         var pathprod = this.$pathprod + current_lang + "/"

         var btn_switch = document.getElementById("switch");
         var envUrl = window.location.href
         var origin_url = window.location.origin
         var switch_url = origin_url.replaceAll('rhapsody', 'harmony')
         var ancre_Url = window.location.hash
         var urlPath_EH = "entry-harmony"
         
         if(!btn_switch.checked){
            if(envUrl == origin_url + pathprod + "composition-inclusive"){
                  window.location.href = switch_url + pathprod + urlPath_EH
                  
            }else if(!ancre_Url || envUrl == origin_url + pathprod + "saint-ouen" + ancre_Url){
                  window.location.href = envUrl.replaceAll('rhapsody', 'harmony');
            }else{
               switch (ancre_Url) {
                  //------------------------------
                  //Ancrage Composition inclusive
                  //------------------------------
                  case "#esquisse-projet":
                     window.location.href = switch_url + pathprod + urlPath_EH +"#geste-precis"
                     break;

                  case "#espace-rassembleurs":
                     window.location.href = switch_url + pathprod + urlPath_EH +"#dynamique-services"
                     break;

                  case "#pollinisateur":
                     window.location.href = switch_url + pathprod + urlPath_EH +"#vivre-harmony"
                     break;

                  case "#roof-top":
                     window.location.href = switch_url + pathprod + urlPath_EH +"#roof-top"
                     break;

                  default:
                     window.location.href = switch_url + pathprod + urlPath_EH
                     break;
               }
            }
         }
      },
      
      switch_lang(){
         var french = document.getElementById("french").checked
         this.showMenu = true
         
         if(french){
            document.querySelector(".switch_fr").classList.add("currentLang")
            document.querySelector(".switch_uk").classList.remove("currentLang")
         }else{
            document.querySelector(".switch_fr").classList.remove("currentLang")
            document.querySelector(".switch_uk").classList.add("currentLang")
         }
      }
   },

   computed: {
      lang: {
         get: function() {
            return this.$store.state.locale
         },
         set: function(newVal) {
            this.$store.dispatch('changeLocale', newVal)
         }
      }
   },
   
   mounted(){
      //Gestion switch button checked to rhapsody
      window.addEventListener("load", function(){
         var siteName = window.location.href;
         var switchBtn = document.getElementById("switch")
         if(siteName.includes('rhapsody')){
            switchBtn.checked = true;
         }
      })

      //Check langue switch onLoad page
      switch (this.$i18n.locale) {
         case "fr":
               document.querySelector(".switch_fr").classList.add("currentLang")
               document.querySelector(".switch_uk").classList.remove("currentLang")
         
            break;
         case "en":
               document.querySelector(".switch_fr").classList.remove("currentLang")
               document.querySelector(".switch_uk").classList.add("currentLang")
            break;
         default:
            break;
      }
   }
}
</script>